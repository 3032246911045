.mapboxgl-ctrl-top-right {
  /* top: 100px !important;
  right: 5px !important; */
  display: none;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100% !important;
  font-size: 12px !important;
  font-family: "Nunito Sans", sans-serif !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  border-radius: 6px !important;
  line-height: 17px !important;
}

.mapboxgl-ctrl-geocoder--input {
  height: 34px !important;
  padding: 6px 33px !important;
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: none !important;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address,
.mapboxgl-ctrl-geocoder--powered-by {
  font-size: 12px !important;
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 7px !important;
  left: 6px !important;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  top: 0px !important;
  right: 5px !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 3px !important;
  margin-right: 1px !important;
  height: 15px !important;
  width: 15px !important;
}

.mapboxgl-popup-close-button {
  top: 2px !important;
  right: 4px !important;
  font-size: 18px;
}

.mapboxgl-popup {
  max-width: 350px !important;
}

@media (min-width: 1024px) {
  .mapboxgl-ctrl-top-right {
    top: 60px !important;
    right: 5px !important;
    display: block;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .mapboxgl-ctrl-top-right {
    top: 100px !important;
    right: 5px !important;
    display: block;
  }
}

@media (min-width: 768px) {
  .mapboxgl-ctrl-geocoder {
    font-size: 14px !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
